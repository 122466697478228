import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { filterLotteryApi, getPeriodLotteryByIdApi } from "../../api/lottery";
import { findRewardByPeriodIdGroupByUserIdApi } from "../../api/order";
import Breadcrumb from "../../component/breadcrumb";
import Pagination from "../../component/pagination";
import { ILottery, IPeriodLottery } from "../../interface/lottery";
import Meta from "../../interface/meta";
import { IOrderRewardGroupByUserId } from "../../interface/order";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";

const LotteryWinner = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(false);
  const [periodLottery, setPeriodLottery] = useState<IPeriodLottery>();
  const [userRewards, setuserRewards] = useState<IOrderRewardGroupByUserId[]>([]);
  const [lottery, setLottery] = useState<ILottery[]>([]);
  const [meta, setMeta] = useState<Meta>({
    page: 1,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [filter, setFilter] = useState({
    number: "",
    awardTier: "",
  })

  const tier = {
    first: "รางวัลที่ 1",
    second: "รางวัลที่ 2",
    third: "รางวัลที่ 3",
    fourth: "รางวัลที่ 4",
    fifth: "รางวัลที่ 5",
    near1: "รางวัลข้างเคียงรางวัลที่ 1",
    first3: "รางวัลเลขหน้า 3 ตัว",
    last3: "รางวัลเลขท้าย 3 ตัว",
    last2: "รางวัลเลขท้าย 2 ตัว",
  }

  const initData = async () => {
    try {
      setLoading(true);

      const period = await getPeriodLotteryByIdApi(id!);
      const [rewards, lottery] = await Promise.all([
        findRewardByPeriodIdGroupByUserIdApi(id!),
        filterLotteryApi({
          periodId: id!,
          win: "true",
          page: meta.page,
          limit: meta.limit
        })
      ]);

      setPeriodLottery(period.data);
      setuserRewards(rewards.data ?? []);
      setLottery(lottery.data.results ?? [])
      setMeta(prev => ({
        ...prev,
        totalCount: lottery.data.meta.totalCount,
        hasMore: lottery.data.meta.hasMore,
      }));
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/lottery`)
    }
  }

  const filterLottery = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterLotteryApi({
        periodId: id!,
        win: "true",
        number: filter.number,
        awardTier: filter.awardTier,
        page,
        limit,
      });

      setLottery(data.results ?? [])
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    filterLottery(1, meta.limit);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Breadcrumb
        title={`งวดวันที่ ${periodLottery?.period.day ?? ""} ${!!periodLottery ? moment.months(periodLottery!.period.month - 1) : ""} ${!!periodLottery ? (periodLottery!.period.year + 543) : ""}`}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "งวดลอตเตอรี่", link: "/lottery" },
          { title: `งวดวันที่ ${periodLottery?.period.day ?? ""} ${!!periodLottery ? moment.months(periodLottery!.period.month - 1) : ""} ${!!periodLottery ? (periodLottery!.period.year + 543) : ""}` },
        ]}
      />

      <div className="row">
        <div className="col-12">
          <div className="card mb-2">
            <div className="card-body">
              <span className="h4">งวดประจำวันที่ {periodLottery?.period.day ?? ""} {!!periodLottery ? moment.months(periodLottery!.period.month - 1) : ""} {!!periodLottery ? (periodLottery!.period.year + 543) : ""}</span><br />
              <br />
              <div className="row mb-4">
                <div className="col">
                  <span className="tx-13 text-muted">มีผู้ถูกรางวัลทั้งหมด</span><br />
                  <span className="h1 text-primary"> {userRewards.length}</span> คน
                </div>
                <div className="col">
                  <span className="tx-13 text-muted">มูลค่ารวม</span><br />
                  <span className="h1 text-primary">
                    {
                      `฿${userRewards.reduce((sum, user) => {
                        return user.rewards.reduce((totalReward, rw) => rw.totalReward + totalReward, 0) + sum
                      }, 0).toLocaleString("en-US")}`
                    }
                  </span>
                </div>

                {/* <div className="col-auto">
                  <a href="#" id="search" className="btn btn-outline-primary mt-3"><span className="fa fa-download mr-2"></span>Export</a>
                </div> */}
              </div>

              <div className="table-responsive">
                <table id="dataTable" className="table">
                  <thead>
                    <tr>
                      <th style={{ width: 250 }}>ผู้ซื้อ</th>
                      <th>รางวัล</th>
                      <th>เลขบัญชี</th>
                      <th className="text-right">มูลค่ารวม</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      userRewards.length > 0 ? userRewards.map(item => {
                        return (
                          <tr>
                            <td>
                              <span className='hover-underline cursor-pointer' onClick={() => navigate(`${process.env.PUBLIC_URL}/users/edit/${item.user.id}`)}> {item.user.firstName} {item.user.lastName}</span>
                              <br />
                              <span className="tx-12 text-muted">โทร {item.user.phoneNumber}</span>
                            </td>
                            <td>
                              {
                                item.rewards.map(rw => {
                                  return (
                                    <p>{tier[rw.tier] ?? ""} {rw.lotteryNumber}x{rw.totalInSet} มูลค่า ฿{rw.totalReward.toLocaleString("en-US")}</p>
                                  )
                                })
                              }
                            </td>
                            <td>
                              {
                                !!item.bankAccount ? (
                                  item.bankAccount.length > 0 ? (
                                    `${item.bankAccount.find(e => e.isRewardAccount)?.bank?.name ?? ""} ${item.bankAccount.find(e => e.isRewardAccount)?.accountNumber}`
                                  ) : ""
                                ) : ""
                              }
                            </td>
                            <td className="text-right">
                              <h4>฿{item.rewards.reduce((totalReward, rw) => rw.totalReward + totalReward, 0).toLocaleString("en-US")}</h4>
                            </td>
                          </tr>
                        )
                      }) : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            ไม่พบรายการ
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4 mt-3">
                  <span className="mr-2 ">ทั้งหมด</span>
                  <span className="h2 mr-2 font-weight-bold- c-black-">{meta.totalCount.toLocaleString("en-US")}</span><span>ใบ</span>
                </div>
                <div className="col-lg p-0">
                  <div className="form-row mb-2 ">
                    <div className="col-lg ">
                      <span className="tx-13 text-muted">ค้นหา</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="ค้นหาล็อตเตอรี่"
                        value={filter.number}
                        onChange={(e) => {
                          setFilter(prev => ({
                            ...prev,
                            number: e.target.value,
                          }))
                        }}
                      />
                    </div>
                    <div className="col-lg">
                      <span className="tx-13 text-muted">รางวัล</span>
                      <select
                        className="form-control"
                        value={filter.awardTier}
                        onChange={(e) => {
                          setFilter(prev => ({
                            ...prev,
                            awardTier: e.target.value,
                          }))
                        }}
                      >
                        <option value="">แสดงทั้งหมด</option>
                        <option value="first">รางวัลที่ 1</option>
                        <option value="first3">เลขหน้า 3 ตัว</option>
                        <option value="last3">เลขท้าย 3 ตัว</option>
                        <option value="last2">เลขท้าย 2 ตัว</option>
                        <option value="second">รางวัลที่ 2</option>
                        <option value="third">รางวัลที่ 3</option>
                        <option value="fourth">รางวัลที่ 4</option>
                        <option value="fifth">รางวัลที่ 5</option>
                      </select>
                    </div>
                    {/* <div className="col-auto">
                      <a href="#" id="search" className="btn btn-outline-primary mt-4"><span className="fa fa-download mr-2"></span>Export</a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="table-responsive">
                  <table id="dataTable" className="table">
                    <thead>
                      <tr>
                        <th style={{ width: 250 }}>รูป</th>
                        <th>ปี-งวด-ชุดที่</th>
                        <th className="text-center">เลข</th>
                        <th>จัดชุด</th>
                        <th>ผู้ซื้อ</th>
                        <th className="text-right">รางวัล</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        lottery.length > 0 ? lottery.map(e => {
                          console.log(!!e.buyer)

                          return (
                            <tr key={e.id}>
                              <td>
                                <div className="thumbnail_lotto">
                                  <img className="lazy img-responsive" alt="" src={getSrcImage(e.picture, "?tr=w-250")} />
                                </div>
                              </td>
                              <td>{e.yearPeriodSet}</td>
                              <td>
                                <h3 className=" text-center">{e.number}</h3>
                              </td>
                              <td>{e.totalInSet} ใบ</td>
                              <td>
                                {
                                  !!e.buyer ? (
                                    <>
                                      <span className='hover-underline cursor-pointer' onClick={() => navigate(`${process.env.PUBLIC_URL}/users/edit/${e.buyer!.id}`)}> {e.buyer!.firstName} {e.buyer!.lastName}</span>
                                      <br />
                                      <span className="tx-12 text-muted">โทร {e.buyer!.phoneNumber}</span>
                                    </>
                                  ) : <></>
                                }
                              </td>
                              <td className="text-right" style={{ verticalAlign: "middle" }}>
                                {
                                  e.awards?.map((award, index) => {
                                    return (
                                      <div key={`${e.id}_${award.round}`} className={index !== (e.awards!.length - 1) ? "mb-4" : ""}>
                                        <h4 style={{
                                          margin: 0,
                                          lineHeight: "0.5rem"
                                        }} className="text-success">{tier[award.tier] ?? ""}</h4>
                                        <span className="tx-12 text-muted">รางวัลละ {award.reward.toLocaleString("en-US")} บาท</span>
                                      </div>
                                    )
                                  })
                                }
                              </td>
                            </tr>
                          )
                        }) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              ไม่พบรายการ
                            </td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <Pagination
            active={meta.page}
            limit={meta.limit}
            onPageChange={(page) => {
              filterLottery(page, meta.limit);
            }}
            pageRangeDisplayed={5}
            total={meta.totalCount}
          />
        </div>
      </div>
    </>
  );
}

export default LotteryWinner;