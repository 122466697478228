export const getSrcImage = (src?: string, transformation?: string): string => {
  if (!src)
    return `${process.env.PUBLIC_URL}/images/no-img.jpeg`

  if (src.startsWith("http://") || src.startsWith("https://"))
    return src;

  if (src.startsWith("images"))
    return `${process.env.PUBLIC_URL}/${src}`

  return `${process.env.REACT_APP_IMAGE_HOST}${src}${transformation ?? ""}`
}