import { FC, ReactNode, useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb";
import { useSnackbar } from 'react-simple-snackbar'
import { useAppDispatch } from "../../redux/hooks";
import { setAppLoading } from "../../redux/app.reducer";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getSrcImage } from "../../utils/utils";
import { ICreateFooter } from "../../interface/footer";
import { createFooterApi, getFooterByIdApi, updateFooterByIdApi } from "../../api/footer";
import { filterNewsApi, getNewsByIdApi } from "../../api/news";
import { AsyncPaginate } from "react-select-async-paginate";

const $ = require('jquery');
$.dropify = require("dropify");

interface IFooterForm {
  mode: "new" | "edit"
}
const FooterForm: FC<IFooterForm> = ({ mode }) => {
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  // const [begin, setBegin] = useState(moment().format("yyyy-MM-DD"))
  // const [end, setEnd] = useState(moment().format("yyyy-MM-DD"))
  const [footer, setFooter] = useState<ICreateFooter & { submit: boolean }>({
    picture: "",
    begin: moment().unix(),
    end: moment().unix(),
    goto: "invite",
    gotoRef: "",
    status: 1,
    submit: false,
  });
  const [loading, setLoading] = useState(false);
  const [gotoRef, setGotoRef] = useState<any>();
  const maxWidth = 700;
  const maxHeight = 200;

  const initDropify = (defaultFile?: string) => {
    const drEvent = $('.dropify').dropify({
      defaultFile: !!defaultFile ? getSrcImage(defaultFile) : "",
      error: {
        'minWidth': `จำกัดขนาดความกว้างของรูปที่ ${maxWidth}px`,
        'maxWidth': `จำกัดขนาดความกว้างของรูปที่ ${maxWidth}px`,
        'minHeight': `จำกัดขนาดความสูงของรูปที่ ${maxHeight}px`,
        'maxHeight': `จำกัดขนาดความสูงของรูปที่ ${maxHeight}px`,
      }
    });

    drEvent.on('dropify.afterClear', function (event, element) {
      setFooter(prev => ({
        ...prev,
        picture: "",
      }))
    });
  }

  const getFooterById = async () => {
    try {
      setLoading(true);

      const { data } = await getFooterByIdApi(id!);
      setFooter((prev) => ({
        ...prev,
        ...data
      }))

      initDropify(data.picture)
      switch (data.goto) {
        case "url": {
          setGotoRef(data.gotoRef);
          break
        }
        case "news": {
          if (data.gotoRef === "") break;
          const news = await getNewsByIdApi(data.gotoRef);
          setGotoRef({ value: news?.data?.id ?? "", label: news?.data?.title });
          break
        }
        default:
          break
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/banners/footer`)
    }
  }

  const createFooter = async (payload: ICreateFooter) => {
    try {
      if (payload.picture === "") {
        openSnackbar("กรุณาใส่รูป");
        return;
      } else if (payload.begin > payload.end) {
        openSnackbar("วันที่สิ้นสุดจำป็นต้องมากกว่าวันที่เริ่มต้น");
        return;
      }
      setLoading(true);
      await createFooterApi(payload);
      setLoading(false);
      openSnackbar("Successfuly");
      setTimeout(() => {
        navigate(`${process.env.PUBLIC_URL}/banners/footer`)
      }, 500)
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const updateFooterById = async (payload: ICreateFooter) => {
    try {
      if (payload.picture === "") {
        openSnackbar("กรุณาใส่รูป");
        return;
      } else if (payload.begin > payload.end) {
        openSnackbar("วันที่สิ้นสุดจำป็นต้องมากกว่าวันที่เริ่มต้น");
        return;
      }
      setLoading(true);

      await updateFooterByIdApi(id!, payload);

      setLoading(false);
      openSnackbar("Successfuly");
      setTimeout(() => {
        navigate(`${process.env.PUBLIC_URL}/banners/footer`)
      }, 500)
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const filterNews = async (search, loadedOptions, additional) => {
    try {
      const { data } = await filterNewsApi({
        keyword: search,
        status: "1",
        page: additional.page as number,
        limit: 10,
      });
      return ({
        options: data.results?.map(e => ({ value: e.id, label: e.title })) ?? [],
        hasMore: data.meta.hasMore,
        additional: {
          page: (additional.page as number) + 1,
        },
      });

    } catch (e: any) {
      console.log(e);
      return ({
        options: [],
        hasMore: false,
        additional: {
          page: (1 + 1) as number,
        },
      });
    }
  }

  const renderLandingOpts = (goto: string): ReactNode => {
    switch (goto) {
      case "url": {
        return (
          <div className="form-group">
            <input
              className="form-control"
              value={gotoRef}
              onChange={(e) => {
                setGotoRef(e.target.value)
                setFooter(prev => ({
                  ...prev,
                  gotoRef: e.target.value
                }))
              }}
            />
          </div>
        )
      }
      case "news": {
        return (
          <div className="form-group">
            <AsyncPaginate
              debounceTimeout={500}
              value={gotoRef}
              additional={{
                page: 1,
              }}
              styles={{
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                  return {
                    ...styles,
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    textOverflow: "ellipsis",
                  }
                }
              }}
              loadOptions={filterNews}
              onChange={(e: { value: string, label: string }) => {
                setGotoRef(e)
                setFooter(prev => ({
                  ...prev,
                  gotoRef: e.value
                }))
              }}
            />
          </div>
        )
      }
      default:
        return <></>
    }
  }

  useEffect(() => {
    if (mode === "new") {
      initDropify()
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (mode === "edit") {
      getFooterById();
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Breadcrumb
        title={`${mode === "new" ? "เพิ่ม" : "แก้ไข"} Footer`}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "Footer", link: "/banner/footer" }
        ]}
      />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body" >
              <form onSubmit={(e) => {
                e.preventDefault();
                if (mode === "new") {
                  createFooter({
                    ...footer
                  });
                } else {
                  updateFooterById({
                    ...footer
                  });
                }
              }}>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label required">รูป</label>
                  <div className="col-sm-5">
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        onChange={(e) => {
                          let reader = new FileReader();
                          reader.readAsDataURL(e.target.files![0]);
                          reader.onload = function () {
                            setFooter(prev => ({
                              ...prev,
                              picture: reader.result as string,
                            }))
                          };
                        }}
                        className="dropify"
                        data-max-file-size="3M"
                        data-allowed-file-extensions="jpg png jpeg"
                        data-height="400"
                        data-max-height={maxHeight + 1}
                        data-min-height={maxHeight - 1}
                        data-max-width={maxWidth + 1}
                        data-min-width={maxWidth - 1}
                      />
                      <div>
                        <div className="text-danger text-small">* จำกัดขนาดความกว้างของรูปที่ {maxWidth}px</div>
                        <div className="text-danger text-small">* จำกัดขนาดความสูงของรูปที่ {maxHeight}px</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label required">เริ่มต้น</label>
                  <div className="col-sm-3">
                    <input
                      type="date"
                      required
                      className="form-control"
                      value={moment.unix(footer.begin).format("yyyy-MM-DD")}
                      onChange={(e) => {
                        setFooter(prev => ({
                          ...prev,
                          begin: moment(`${e.target.value} 00:00:00`).unix()
                        }))
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label required">สิ้นสุด</label>
                  <div className="col-sm-3">
                    <input
                      type="date"
                      required
                      className="form-control"
                      value={moment.unix(footer.end).format("yyyy-MM-DD")}
                      onChange={(e) => {
                        setFooter(prev => ({
                          ...prev,
                          end: moment(`${e.target.value} 23:59:59`).unix()
                        }))
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label required">Landing page</label>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <select className="form-control" value={footer.goto} onChange={e => {
                        setFooter(prev => ({
                          ...prev,
                          goto: e.target.value,
                        }))
                      }}>
                        <option value={"invite"}>ชวนเพื่อน</option>
                        <option value={"news"}>ข่าว</option>
                        <option value={"product"}>สินค้าอัพเกรด</option>
                        <option value={"url"}>URL</option>
                      </select>
                    </div>

                    {renderLandingOpts(footer.goto)}

                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label required">สถานนะ</label>
                  <div className="col-sm-3">
                    <select className="form-control" value={footer.status} onChange={e => {
                      setFooter(prev => ({
                        ...prev,
                        status: +e.target.value,
                      }))
                    }}>
                      <option value={1}>ใช้งาน</option>
                      <option value={0}>ไม่ใช้งาน</option>
                      <option value={2}>หมดอายุ</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <button className="btn btn-outline-primary mr-2 ml-auto" onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/banners/footer`)
                  }}>ยกเลิก</button>
                  <button className="btn btn-primary mr-2" type="submit">บันทึก</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}
export default FooterForm;
