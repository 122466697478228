import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  createBankAccountByIdApi,
  getBankAccountByUserIdApi,
  updateBankAccountByIdApi,
} from "../../api/bank-account";
import { getBanksApi } from "../../api/core";
import { getProfileUserByApi } from "../../api/user";
import Breadcrumb from "../../component/breadcrumb";
import IBank from "../../interface/bank";
import {
  IBankAccount,
  IBankAccountRequest,
  ICreateBankAccountRequest,
} from "../../interface/bank-account";
import { IAdminUpdateUser } from "../../interface/user";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
const $ = require("jquery");
$.dropify = require("dropify");

const UserBookBank = () => {
  let { id } = useParams();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [user, setUser] = useState<IAdminUpdateUser>({} as IAdminUpdateUser);
  const [banks, setBanks] = useState<IBank[]>([]);
  const [selectedBankAccount, setSelectedBankAccount] =
    useState<IBankAccount>();
  const [mode, setMode] = useState<string>("new");
  const [loading, setLoading] = useState(false);

  const initData = async () => {
    try {
      setLoading(true);
      await getProfileUserById();
      const [bookBanks, banks] = await Promise.all([
        getBankAccountByUserId(),
        getBanksApi(),
      ]);
      if (bookBanks.length > 0) {
        const acc = bookBanks.find((e) => e.isRewardAccount);
        setSelectedBankAccount(acc!);
        if (!!acc) {
          setMode("edit");
        } else {
          setMode("new");
        }
      }
      setBanks(banks.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const getProfileUserById = async () => {
    try {
      const { data } = await getProfileUserByApi(id!);
      setUser(data as IAdminUpdateUser);
    } catch (e: any) {
      throw e;
    }
  };

  const getBankAccountByUserId = async (): Promise<IBankAccount[]> => {
    try {
      const { data } = await getBankAccountByUserIdApi(id!);
      return data ?? [];
    } catch (e: any) {
      throw e;
    }
  };

  const updateBankAccountById = async (req: IBankAccount) => {
    try {
      setLoading(true);
      const payload: IBankAccountRequest = {
        bankId: req.bankId,
        accountName: req.accountName,
        accountNumber: req.accountNumber,
        branchName: req.branchName,
        isRewardAccount: true,
      };
      await updateBankAccountByIdApi(req.id, payload);
      const bookBanks = await getBankAccountByUserId();
      if (bookBanks.length > 0) {
        const acc = bookBanks.find((e) => e.isRewardAccount);
        setSelectedBankAccount(acc);
        if (!!acc) {
          setMode("edit");
        } else {
          setMode("new");
        }
      }
      openSnackbar("Successfuly");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const createBankAccount = async (req: IBankAccount) => {
    try {
      setLoading(true);
      const payload: ICreateBankAccountRequest = {
        userId: id!,
        bankId: req.bankId,
        accountName: req.accountName,
        accountNumber: req.accountNumber,
        branchName: req.branchName,
        isRewardAccount: true,
      };
      await createBankAccountByIdApi(payload);
      const bookBanks = await getBankAccountByUserId();
      if (bookBanks.length > 0) {
        const acc = bookBanks.find((e) => e.isRewardAccount);
        setSelectedBankAccount(acc);
        if (!!acc) {
          setMode("edit");
        } else {
          setMode("new");
        }
      }
      openSnackbar("Successfuly");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <Breadcrumb
        title={"สมาชิก"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/users" },
          { title: `${user?.firstName ?? ""} ${user?.lastName ?? ""}` },
          { title: "บัญชีรับรางวัล" },
        ]}
      />

      <div className="row">
        <div className="col-12 m-0">
          <div className="row">
            <div className="col">
              <ul
                className="nav nav-pills nav-fill"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">ประวัติส่วนตัว</span> */}
                  <Link className="nav-link" to={`/users/edit/${id}`}>
                    ประวัติส่วนตัว
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">รายได้</span> */}
                  <Link className="nav-link" to={`/users/income/${id}`}>
                    รายได้
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">ประวิติการใช้งาน</span> */}
                  <Link className="nav-link" to={`/users/history/${id}`}>
                    ประวิติการใช้งาน
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <span className="nav-link active">บัญชีรับรางวัล</span>
                  {/* <Link className="nav-link" to={`/users/book-bank/${id}`}>บัญชีรับรางวัล</Link> */}
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">ทีมงาน</span> */}
                  <Link className="nav-link" to={`/users/team/${id}`}>
                    ทีมงาน
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!selectedBankAccount) return;
                  if (mode === "new") {
                    createBankAccount(selectedBankAccount);
                  } else {
                    updateBankAccountById(selectedBankAccount);
                  }
                }}
              >
                <div className="form-group row">
                  <div className="col-12 border-bottom">
                    <h5>รายละเอียดของบัญชีธนาคาร</h5>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label required"
                  >
                    ธนาคาร
                  </label>
                  <div className="col-sm-9">
                    <select
                      required
                      className="form-control"
                      value={selectedBankAccount?.bankId ?? ""}
                      onChange={(e) => {
                        setSelectedBankAccount((prev) => ({
                          accountName: prev?.accountName ?? "",
                          accountNumber: prev?.accountNumber ?? "",
                          branchName: prev?.branchName ?? "",
                          bankId: e.target.value,
                          id: prev?.id ?? "",
                          isRewardAccount: prev?.isRewardAccount ?? true,
                        }));
                      }}
                    >
                      <option value={""}>เลือก</option>
                      {banks?.map((bank) => (
                        <option key={bank.id} value={bank.id}>
                          {bank.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label required"
                  >
                    ชื่อ-นามสกุลเจ้าของบัญชี
                  </label>
                  <div className="col-sm-9">
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="เจ้าของบัญชี"
                      value={selectedBankAccount?.accountName}
                      onChange={(e) => {
                        setSelectedBankAccount((prev) => ({
                          accountName: e.target.value,
                          accountNumber: prev?.accountNumber ?? "",
                          branchName: prev?.branchName ?? "",
                          bankId: prev?.bankId ?? "",
                          id: prev?.id ?? "",
                          isRewardAccount: prev?.isRewardAccount ?? true,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label required"
                  >
                    หมายเลขับญชีธนาคาร
                  </label>
                  <div className="col-sm-9">
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="หมายเลขับญชีธนาคาร"
                      value={selectedBankAccount?.accountNumber}
                      onChange={(e) => {
                        setSelectedBankAccount((prev) => ({
                          accountNumber: e.target.value,
                          accountName: prev?.accountName ?? "",
                          branchName: prev?.branchName ?? "",
                          bankId: prev?.bankId ?? "",
                          id: prev?.id ?? "",
                          isRewardAccount: prev?.isRewardAccount ?? true,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-3 col-form-label required"
                  >
                    สาขา
                  </label>
                  <div className="col-sm-9">
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="สาขา"
                      value={selectedBankAccount?.branchName}
                      onChange={(e) => {
                        setSelectedBankAccount((prev) => ({
                          branchName: e.target.value,
                          accountNumber: prev?.accountNumber ?? "",
                          accountName: prev?.accountName ?? "",
                          bankId: prev?.bankId ?? "",
                          id: prev?.id ?? "",
                          isRewardAccount: prev?.isRewardAccount ?? true,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="col-12 border-top pt-4">
                  <div className="row">
                    <button className="btn btn-primary ml-auto mr-2">
                      แก้ไข
                    </button>
                    <button
                      type="button"
                      className="btn btn-light"
                      data-dismiss="modal"
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserBookBank;
