import moment from "moment";
import { useState, useEffect, ReactNode } from "react";
import ReactDatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import { filterFooterApi } from "../../api/footer";
import Breadcrumb from "../../component/breadcrumb";
import ModalViewImage from "../../component/modal-view-image";
import Pagination from "../../component/pagination";
import { IFooter } from "../../interface/footer";
import Meta from "../../interface/meta";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";


const Footer = () => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(false);
  const [footer, setFooter] = useState<IFooter[]>([]);
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [filter, setFilter] = useState({
    begin: 0,
    end: 0,
    status: "",
  })
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);


  const filterFooter = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterFooterApi({
        page,
        limit,
        begin: filter.begin !== 0 ? moment.unix(filter.begin).utc().unix() : 0,
        end: filter.end !== 0 ? moment.unix(filter.end).utc().unix() : 0,
        status: !filter.status ? undefined : filter.status,
      });

      setFooter(data.results ?? [])
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    filterFooter(1, meta.limit);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <div className="dashboard-header d-flex flex-column grid-margin ">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <Breadcrumb
            title={"Footer"}
            children={[
              { title: "หน้าแรก", link: "/" },
              { title: "Footer" }
            ]}
          />
          <button className="btn btn-primary ml-auto" onClick={() => {
            navigate(`${process.env.PUBLIC_URL}/banners/footer/add`)
          }}>
            <span className="fa fa-plus mr-2"></span>เพิ่มข้อมูล
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills  active" id="pills-tab" role="tablist">
            <li className="nav-item mt-2">
              {/* <span className="nav-link active">Banner</span> */}
              <Link className="nav-link" to="/banners">Banner</Link>
            </li>
            <li className="nav-item mt-2">
              <span className="nav-link active">Footer</span>
              {/* <Link className="nav-link" to="/banners/footer">Footer</Link> */}
            </li>
          </ul>
        </div>
      </div>


      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-8 ">
                  <span className="mr-2 ">ทั้งหมด {meta.totalCount} รายการ</span>
                </div>
                <div className="col-lg p-0">
                  <div className="form-row mb-2 ">
                    <div className="col-md mb-1">
                      <ReactDatePicker
                        selected={startDate}
                        onChange={(dates: [Date | null, Date | null]) => {
                          const [start, end] = dates;
                          setStartDate(start);
                          setEndDate(end);
                          if ((!!start && !!end) || (!start && !end)) {
                            setFilter(prev => ({
                              ...prev,
                              begin: (start?.valueOf() ?? 0) / 1000,
                              end: (end?.valueOf() ?? 0) / 1000,
                            }))
                          }
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        dateFormat={"dd/MM/yyyy"}
                        placeholderText="วันที่ จาก - ถึง"
                        isClearable={true}
                        customInput={
                          <input className="form-control" />
                        }
                      />
                    </div>
                    <div className="col-lg ml-auto">
                      <div className="form-group" >
                        <select className="form-control" value={filter.status}
                          onChange={(e) => {
                            setFilter(prev => ({
                              ...prev,
                              status: e.target.value,
                            }))
                          }}>
                          <option value="">สถานนะ</option>
                          <option value="1">ใช้งาน</option>
                          <option value="0">ไม่ใช้งาน</option>
                          <option value="2">หมดอายุ</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="table-responsive">
                  <table id="dataTable" className="table">
                    <thead>
                      <tr>
                        <th>รูป</th>
                        <th>เริ่มต้น</th>
                        <th>สิ้นสุด</th>
                        <th>สถานนะ</th>
                        <th className="text-right">ดำเนินการ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        footer.length > 0 ? (
                          footer.map(e => {
                            let status: ReactNode;
                            switch (e.status) {
                              case 0:
                                status = (
                                  <span className="badge badge-danger h5 font-weight-bold">ไม่ใช้งาน</span>
                                );
                                break;
                              case 1:
                                status = (
                                  <span className="badge badge-success h5 font-weight-bold">ใช้งาน</span>
                                );
                                break;
                              case 2:
                                status = (
                                  <span className="badge badge-light h5 font-weight-bold">หมดอายุ</span>
                                );
                                break;
                              default:
                                status = (
                                  <span className="badge badge-small badge-light">-</span>
                                );
                            }

                            return (
                              <tr key={e.id}>
                                <td>
                                  <div className="cursor-pointer" onClick={() => {
                                    setShowImage({
                                      show: true,
                                      src: getSrcImage(e.picture)
                                    })
                                  }}>
                                    <img style={{ height: 50, width: "auto" }} className="lazy img-responsive" alt="" src={getSrcImage(e.picture, "?tr=h-50")} />
                                  </div>
                                </td>
                                <td>{moment.unix(e.begin).format("DD/MM/yyyy")}</td>
                                <td>{moment.unix(e.end).format("DD/MM/yyyy")}</td>
                                <td>
                                  {status}
                                </td>
                                <td className="text-right">
                                  <button className="btn btn-outline-primary mr-1 mb-1 pt-2" onClick={() => {
                                    navigate(`${process.env.PUBLIC_URL}/banners/footer/edit/${e.id}`)

                                  }}>
                                    <span className="fa fa-edit "></span>
                                  </button>
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <>
                            <tr>
                              <td colSpan={5}>
                                <div className="text-center">ไม่พบรายการ</div>
                              </td>
                            </tr>
                          </>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <Pagination
                active={meta.page}
                limit={meta.limit}
                onPageChange={(page) => {
                  filterFooter(page, meta.limit);
                }}
                pageRangeDisplayed={5}
                total={meta.totalCount}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage(prev => ({
            ...prev,
            show: false,
          }))
        }}
      />
    </>
  )
}
export default Footer;
