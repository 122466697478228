import { Link, Outlet, useLocation } from "react-router-dom";
import Breadcrumb from "../../component/breadcrumb";

const Setting = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Breadcrumb
        title={"ตั้งค่า"}
        children={[{ title: "หน้าแรก", link: "/" }, { title: "ตั้งค่า" }]}
      />

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item mt-2">
              <Link
                className={`nav-link ${
                  pathname.endsWith("/setting") ? "active" : ""
                }`}
                to={`${process.env.PUBLIC_URL}/setting`}
              >
                รายละเอียดบริษัท
              </Link>
            </li>
            <li className="nav-item mt-2">
              <Link
                className={`nav-link ${
                  pathname.endsWith("setting/admin") ? "active" : ""
                }`}
                to={`${process.env.PUBLIC_URL}/setting/admin`}
              >
                ผู้ดูแลระบบ
              </Link>
            </li>
            <li className="nav-item mt-2">
              <Link
                className={`nav-link ${
                  pathname.endsWith("setting/pricing") ? "active" : ""
                }`}
                to={`${process.env.PUBLIC_URL}/setting/pricing`}
              >
                ตั้งราคา
              </Link>
            </li>
            <li className="nav-item mt-2">
              <Link
                className={`nav-link ${
                  pathname.endsWith("setting/convertion-rate") ? "active" : ""
                }`}
                to={`${process.env.PUBLIC_URL}/setting/convertion-rate`}
              >
                ตั้งค่าอัตราแลกเปลี่ยน
              </Link>
            </li>
            <li className="nav-item mt-2">
              <Link
                className={`nav-link ${
                  pathname.endsWith("setting/affiliate-commission")
                    ? "active"
                    : ""
                }`}
                to={`${process.env.PUBLIC_URL}/setting/affiliate-commission`}
              >
                ตั้งค่าคอมมิชชั่น
              </Link>
            </li>
            {/* <li className="nav-item mt-2">
              <Link
                className={`nav-link ${pathname.endsWith("setting/commission-config") ? "active" : ""}`}
                to={`${process.env.PUBLIC_URL}/setting/commission-config`}
              >
                ตั้งค่าสายงาน
              </Link>
            </li> */}
          </ul>
        </div>
      </div>

      <Outlet />
    </>
  );
};
export default Setting;
