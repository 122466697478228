import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import Meta from "../../interface/meta";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import Breadcrumb from "../../component/breadcrumb";
import { getProfileUserByApi } from "../../api/user";
import { IUser } from "../../interface/user";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import Pagination from "../../component/pagination";
import FileSaver from "file-saver";
import { filterWalletsApi } from "../../api/wallet";
import { IWallet } from "../../interface/wallet";
import { toBath } from "../../utils/moneyFormatter";

const UserIncome = () => {
  let { id } = useParams();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<IUser>();
  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [filter, setFilter] = useState({
    orderNo: "",
    begin: 0,
    end: 0,
  });

  const getProfileUserById = async (): Promise<IUser> => {
    try {
      const { data } = await getProfileUserByApi(id!);
      setUser(data);
      return data;
    } catch (e: any) {
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      throw e;
    }
  };

  const filterHistoryDeposit = async (page: number, limit: number) => {
    try {
      const { data } = await filterWalletsApi({
        userId: id!,
        type: "1",
        orderNo: filter.orderNo !== "" ? filter.orderNo : undefined,
        begin: filter.begin !== 0 ? moment.unix(filter.begin).utc().unix() : 0,
        end: filter.end !== 0 ? moment.unix(filter.end).utc().unix() : 0,
        page,
        limit,
      });

      setWallets(data.results ?? []);

      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const exportCsv = async () => {
    try {
      setLoading(true);
      const response = await filterWalletsApi({
        userId: id!,
        type: "1",
        format: "csv",
        orderNo: filter.orderNo !== "" ? filter.orderNo : undefined,
        begin:
          filter.begin !== 0
            ? moment.unix(filter.begin).utc().unix()
            : undefined,
        end:
          filter.end !== 0 ? moment.unix(filter.end).utc().unix() : undefined,
      });

      let BOM = "\uFEFF";
      let csvData = (BOM + response.data) as any;
      let blob = new Blob([csvData], {
        type: "text/csv;charset=utf-8",
      });

      FileSaver.saveAs(blob, `history.csv`);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    getProfileUserById();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterHistoryDeposit(1, meta.limit);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <Breadcrumb
        title={"สมาชิก"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/users" },
          { title: `${user?.firstName ?? ""} ${user?.lastName ?? ""}` },
          { title: "รายได้" },
        ]}
      />

      <div className="row">
        <div className="col-12 m-0">
          <div className="row">
            <div className="col">
              <ul
                className="nav nav-pills nav-fill"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">ประวัติส่วนตัว</span> */}
                  <Link className="nav-link" to={`/users/edit/${id}`}>
                    ประวัติส่วนตัว
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <span className="nav-link active">รายได้</span>
                  {/* <Link className="nav-link" to={`/users/income/${id}`}>รายได้</Link> */}
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">ประวิติการใช้งาน</span> */}
                  <Link className="nav-link" to={`/users/history/${id}`}>
                    ประวิติการใช้งาน
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">บัญชีรับรางวัล</span> */}
                  <Link className="nav-link" to={`/users/book-bank/${id}`}>
                    บัญชีรับรางวัล
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">ทีมงาน</span> */}
                  <Link className="nav-link" to={`/users/team/${id}`}>
                    ทีมงาน
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg">
                  <h5 className=" mt-4">ทั้งหมด {meta.totalCount} รายการ</h5>
                </div>
                <div className="col">
                  <div className=" form-row mb-2 mr-2 ">
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="หมายเลขคำสั่งซื้อ"
                        value={filter.orderNo}
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            orderNo: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-md mb-1">
                      <ReactDatePicker
                        selected={startDate}
                        onChange={(dates: [Date | null, Date | null]) => {
                          const [start, end] = dates;
                          setStartDate(start);
                          setEndDate(end);
                          if ((!!start && !!end) || (!start && !end)) {
                            setFilter((prev) => ({
                              ...prev,
                              begin: (start?.valueOf() ?? 0) / 1000,
                              end: (end?.valueOf() ?? 0) / 1000,
                            }));
                          }
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        dateFormat={"dd/MM/yyyy"}
                        placeholderText="วันที่ จาก - ถึง"
                        isClearable={true}
                        customInput={<input className="form-control" />}
                      />
                    </div>
                    <div>
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => {
                          exportCsv();
                        }}
                      >
                        Export
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table
                  id="dataTable"
                  className="table text-wrap table_new"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>หมายเลขคำสั่งซื้อ</th>
                      <th>วันเวลา</th>
                      <th>รายได้</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wallets.length > 0 ? (
                      wallets.map((e) => {
                        return (
                          <tr key={e.id}>
                            <td>
                              <div
                                className="hover-underline cursor-pointer"
                                onClick={() => {
                                  if (e.orderId !== "") {
                                    navigate(
                                      `${process.env.PUBLIC_URL}/orders/${e.orderId}`
                                    );
                                  } else if (e.orderUpgradeId !== "") {
                                    navigate(
                                      `${process.env.PUBLIC_URL}/products-upgrade/orders/${e.orderUpgradeId}`
                                    );
                                  }
                                }}
                              >
                                {e.orderNo}
                              </div>
                            </td>
                            <td>
                              {moment(e.createdAt).format(
                                "DD/MM/yyyy HH:mm:ss"
                              )}
                            </td>
                            <td>{toBath(e.amount)}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={3}>
                          <div className="text-center">ไม่พบรายการ</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                active={meta.page}
                limit={meta.limit}
                onPageChange={(page) => {
                  filterHistoryDeposit(page, meta.limit);
                }}
                pageRangeDisplayed={5}
                total={meta.totalCount}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserIncome;
